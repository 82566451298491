const sufixPV = "pvdigital/api/";
const sufixClient = "api/";
const defaultTenant = "vli-logistica.com.br";
const sufixNotification = "pvdigitalnotificacao/api/";

const devConfig = {
  apiurl: `https://api.vli-logistica.com.br/dev/${sufixPV}`,
  appClientUrl: `https://appdoclientedev.azurewebsites.net/${sufixClient}`,
  authUrl: "https://api.vli-logistica.com.br/dev",
  apiNotificationUrl: `https://api.vli-logistica.com.br/dev/${sufixNotification}`,
  apiSignalRUrl: `https://notificacao-pvdigital-dev-api.azurewebsites.net`,
  jetsonsURL: "https://jetsonsprecosdev.vli-logistica.com.br/",
};

const prodConfig = {
  apiurl: `__ENDPOINTS__/${sufixPV}`,
  appClientUrl: `__ENDPOINTSPROPOSAL__/${sufixClient}`,
  authUrl: "__ENDPOINTS__",
  apiNotificationUrl: `__ENDPOINTS__/${sufixNotification}`,
  apiSignalRUrl: `__ENDPOINTSNOTIFICATION__/`,
  jetsonsURL: "__JETSONS_URL__",
};

const config = {
  ...(process.env.NODE_ENV === "development" && devConfig),
  ...(process.env.NODE_ENV !== "development" && prodConfig),
};

export const msalClientId = {
  clientIdDev: "bc1c43b0-5c13-4eed-aba5-cc844ac77dab",
  clientIdHml: "deb71073-23e2-449a-9ac9-bc60fe2052b9",
  clientIdProd: "6747f4b4-0b67-4cfa-8980-66898dfeba05",
  sensideaClientId: "6da52b98-9179-3234-98cc-6980a2dca367",
  sensideaClientIdNotification: "3fb43e3b-f342-40ce-af46-2ba9e706e075",
};

const currentLocationURL = window.location.origin;

export const PV_HML_URL = "https://pvdigital-hml.vli-logistica.com.br";
export const PV_DSV_URL = "https://pvdigital-dev.vli-logistica.com.br";
export const PV_PROD_URL = "https://pvdigital.vli-logistica.com.br";

const PV_AZURE_PROD_URL = "https://pvdigital.azurewebsites.net";
const PV_AZURE_HML_URL = "https://pvdigital-hml.azurewebsites.net";

const getClientId = () => {
  if (currentLocationURL === PV_PROD_URL || currentLocationURL === PV_AZURE_PROD_URL)
    return msalClientId.clientIdProd;
  else if (currentLocationURL === PV_HML_URL || currentLocationURL === PV_AZURE_HML_URL) {
    return msalClientId.clientIdHml;
  } else {
    return msalClientId.clientIdDev;
  }
};

const msalConfig = {
  auth: {
    clientId: getClientId(),
    authority: "https://login.microsoftonline.com/eef8f41a-1fa3-486c-9dc3-2fa5f63c9231",
    redirectUri: `${currentLocationURL}`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
  forceRefresh: false,
};

const apiRequest = {
  scopes: ["user.read"],
  forceRefresh: false,
};

export { config, msalConfig, apiRequest, loginRequest, defaultTenant };
