import ls from "local-storage";
import API, { authAPI, notificationAPI } from "utils/api";
import { history } from "store/store";

import { basicErrorHandler, coverText, uncoverText } from "utils/utility";
import { PAGE_SALE_LIST } from "utils/urls";
import { msalClientId } from "utils/config";

import * as actions from "./login.actions";
import { getUnreadNotifications } from "store/notifications/notifications.useCases";
import {
  getSystemNotificationsStart,
  getSystemNotificationsSuccess,
  getSystemNotificationsError,
} from "store/notifications/notifications.actions";
import { listHallsOptions } from "store/halls/halls.useCases";
import { listProductsOptions } from "store/products/products.useCases";
import { listClientsOptions } from "store/clients/clients.useCases";
import { getUserSubSegments } from "store/users/users.useCases";
import { listRegionsOptions } from "store/regions/regions.useCases";
import { getSaleTypes } from "store/saleTypes/saleTypes.useCases";

export const auth = (data, succesCallback) => dispatch => {
  dispatch(actions.loginStart());

  const params = {
    userName: data.account.username,
    password: data.idToken,
    ClientId: msalClientId.sensideaClientId,
    grant_type: "client_credentials",
  };

  const paramsNotification = {
    ClientId: msalClientId.sensideaClientIdNotification,
    grant_type: "client_credentials",
  };

  const { userName, password } = params;

  authAPI
    .post("/oauth/access-token", params)
    .then(response => {
      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      let permissionsData;

      ls.clear();
      ls.set("usd", coverText({ accessToken, refreshToken, userName, password }));

      const accessTokenNotification =
        "Basic M2ZiNDNlM2ItZjM0Mi00MGNlLWFmNDYtMmJhOWU3MDZlMDc1Ojk5ZDdlNmJlLWIwMzctNGQ3ZC1iNTcyLWE2OTZlNWNkMjQzOQ==";

      authAPI
        .post("/oauth/access-token", paramsNotification, {
          headers: {
            common: {
              Authorization: accessTokenNotification,
            },
          },
        })
        .then(response => {
          const accessTokenNotification = response.data.access_token;
          ls.set("usd_notification", coverText({ accessTokenNotification }));

          API.get("/v1/Usuario/permissoes")
            .then(responseAuth => {
              const adaptedResponse = responseAuth.data.map(permissao => {
                return { resource: permissao.recurso, permissions: permissao.permissoes };
              });
              permissionsData = adaptedResponse;
              API.get("/v1/Usuario/perfil")
                .then(profileResponse => {
                  const profileData = {
                    nome: profileResponse.data.nome,
                    perfil: profileResponse.data.perfil.descricao,
                    ...profileResponse,
                  };
                  const formattedPermissions = permissionsData.reduce((acc, cur) => {
                    acc[cur.resource] = cur.permissions;
                    return acc;
                  }, {});

                  const authData = {
                    ...profileData,
                    ...formattedPermissions,
                    ...data.idTokenClaims,
                  };
                  ls.set("per", coverText(authData));

                  dispatch(listHallsOptions());
                  dispatch(listProductsOptions());
                  dispatch(listClientsOptions());
                  dispatch(getUserSubSegments());
                  dispatch(listRegionsOptions());
                  dispatch(getSaleTypes());

                  succesCallback();
                  dispatch(actions.loginSuccess());
                  if (history.location.pathname.indexOf("login") !== -1) {
                    history.push(PAGE_SALE_LIST);
                  }
                })
                .catch(error => basicErrorHandler(error, dispatch, actions.loginError));
            })
            .catch(error => basicErrorHandler(error, dispatch, actions.loginError));
        })
        .catch(error => basicErrorHandler(error, dispatch, actions.loginError));
    })
    .catch(error => basicErrorHandler(error, dispatch, actions.loginError));
};

export const notificationLogin = () => dispatch => {
  dispatch(getSystemNotificationsStart());

  notificationAPI
    .get("v1/Sistema")
    .then(response => {
      const id = response.data.filter(item => item.nome === "PVDigital")?.[0]?.id;

      dispatch(getUnreadNotifications(id, uncoverText(ls.get("usd")).userName));
      dispatch(getSystemNotificationsSuccess(id));
    })
    .catch(error => {
      basicErrorHandler(error, dispatch, getSystemNotificationsError);
      basicErrorHandler(error, dispatch, actions.loginError);
    });
};

export const showAuthModal = () => dispatch => {
  dispatch(actions.loginModalShow());
};

export const updateAuthRefresh = () => dispatch => {
  dispatch(actions.updateAuthRefresh());
};
